import { useState, useRef } from "react";
import { DateTime } from "luxon";
import html2canvas from "html2canvas";
import logo from "./logo-vip.svg";
import face from "./face-logo.svg";
import insta from "./insta-logo.svg";
import "./App.css";
import "./responsive.css";
import axios from "axios";

const App = () => {
	const cardComponentRef = useRef();
	const [cpf, setCpf] = useState("");
	const [user, setUser] = useState();
	const [notFound, setNotFound] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const onChangeCpf = (v) => {
		var x = "";

		if (v.length >= 15) {
			x = v
				.replace(/\D/g, "")
				.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);

			if (x) {
				v = !x[2]
					? x[1]
					: x[1] +
					  "." +
					  x[2] +
					  (x[3] ? "." + x[3] : "") +
					  (x[4] ? "/" + x[4] : "") +
					  (x[5] ? "-" + x[5] : "");
			}
		} else {
			x = v
				.replace(/\D/g, "")
				.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);

			if (x) {
				v = !x[2]
					? x[1]
					: x[1] +
					  "." +
					  x[2] +
					  (x[3] ? "." + x[3] : "") +
					  (x[4] ? "-" + x[4] : "");
			}
		}

		setCpf(v);
	};

	const submit = () => {
		setIsLoading(true);
		setNotFound(false);
		axios
			.get(
				`https://api-soitic-h64ar4mnja-uc.a.run.app/users/cartao/${cpf.replace(
					/\D/g,
					""
				)}`
			)
			.then((result) => {
				setIsLoading(false);
				if (result.data) {
					setUser(result.data);
				} else {
					setNotFound(true);
				}
			});
	};

	const download = () => {
		var vp = document
			.getElementById("viewportMeta")
			.getAttribute("content");
		document
			.getElementById("viewportMeta")
			.setAttribute("content", "width=800");

		html2canvas(cardComponentRef.current, {
			backgroundColor: "#FFFFFF",
		})
			.then((canvas) => {
				document.body.appendChild(canvas);
				saveScreenshot(canvas);
			})
			.then(function () {
				document
					.getElementById("viewportMeta")
					.setAttribute("content", vp);
			});
	};

	const saveScreenshot = (canvas) => {
		const fileName = "Meu cartão";
		const link = document.createElement("a");
		link.download = fileName + ".png";
		canvas.toBlob(function (blob) {
			link.href = URL.createObjectURL(blob);
			link.click();
		});
	};

	const CardComponent = () => (
		<div className="card" ref={cardComponentRef}>
			<div className="top">
				<div>
					<h3>Grupo SOITIC</h3>
					<h4>Cartão VIP</h4>
				</div>
				<div className="logo">
					<img src={logo} alt="Logo" width={160} />
				</div>
			</div>
			<div className="bottom">
				<h3>{user.Nome}</h3>
				<p>
					VIP: <b>{user.Código}</b>
				</p>
				{/* <p>
					Cliente desde:{" "}
					<b>
						{user?.DTCadastro &&
							DateTime.fromISO(user?.DTCadastro).toFormat(
								"dd/MM/yyyy"
							)}
					</b>
				</p> */}
			</div>
		</div>
	);

	if (user) {
		return (
			<div className="container">
				<button className="button" onClick={() => download()}>
					Download do Cartão
				</button>
				<CardComponent />
				<div className="containerText">
					<div className="firsBlock">
						<p>
							<b>0800 704 1133</b> - GERAL E ASSISTÊNCIA
						</p>
						<p>
							<b>0800 707 5900</b> - COMERCIAL
						</p>
						<a
							href="https://soitic.com.br"
							target="_blank"
							rel="noreferrer"
						>
							soitic.com.br
						</a>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="container">
			<p className="loginTitle">Digite o CPF/CNPJ</p>
			<input
				type="text"
				className="input"
				value={cpf}
				onChange={(e) => onChangeCpf(e.target.value)}
			/>
			{notFound && <p className="error">Não encontrado</p>}
			<button
				className={isLoading ? "buttonDisabled" : "button"}
				disabled={isLoading}
				onClick={() => submit()}
			>
				{isLoading ? "Carregando" : "Próximo"}
			</button>
		</div>
	);
};

export default App;
